import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { fetchWithTokenRefresh } from '../utils/fetchWithTokenRefresh';
import Sidebar from '../components/Sidebar';
import { FaRegCopy } from "react-icons/fa6";

const View = () => {
  const [videos, setVideos] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage] = useState(9);
  const [isMobile, setIsMobile] = useState(false);
  const [totalVideos, setTotalVideos] = useState(0);
  const intervalRef = useRef(null);
  const navigate = useNavigate();
  const [copiedId, setCopiedId] = useState(null);

  const truncateText = (text, maxWords) => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };

  const copyToClipboard = (text, id) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  const fetchVideos = async (page = 1, limit = 9) => {
    try {
      const response = await fetchWithTokenRefresh(`https://api.vidstudio.ai/user/videos?page=${page}&limit=${limit}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        const sortedVideos = data.videos.sort((a, b) => new Date(b.startedAt) - new Date(a.startedAt));

        setVideos(prevVideos => {
          const videosMap = new Map();
          prevVideos.forEach(video => videosMap.set(video.jobId, video));
          sortedVideos.forEach(video => videosMap.set(video.jobId, video));
          return Array.from(videosMap.values()).sort((a, b) => new Date(b.startedAt) - new Date(a.startedAt));
        });

        setTotalVideos(data.total);
      } else {
        setErrorMessage(data.message || 'Error fetching videos');
      }
    } catch (error) {
      console.error('Error fetching videos:', error);
      setErrorMessage('Error fetching videos');
    }
  };

  useEffect(() => {
    fetchVideos(currentPage, videosPerPage);
  }, [currentPage, videosPerPage]);

  useEffect(() => {
    const hasProcessingVideo = videos.some((video) => video.status === 'Processing');

    if (hasProcessingVideo && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        fetchVideos(1, currentPage * videosPerPage);
      }, 10000);
    } else if (!hasProcessingVideo && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [videos, currentPage, videosPerPage]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const handleLoadMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  if (errorMessage) {
    return <div className="text-red-600 font-semibold">{errorMessage}</div>;
  }

  return (
    <div className="flex min-h-screen bg-gray-100">
      {!isMobile && <Sidebar />}
      <div className="flex-1 py-12 px-6 relative">
        {!isMobile ? (
          <Link to="/profile" className="absolute top-4 right-7 text-sm text-black font-semibold hover:underline">
            Account
          </Link>
        ) : (
          <div className="flex justify-between mb-4">
            <button
              onClick={() => navigate('/dashboard/generate')}
              className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
            >
              Create Video
            </button>
            <button
              onClick={() => navigate('/profile')}
              className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
            >
              Account
            </button>
          </div>
        )}
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-6">Your Videos</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {videos.map((video) => (
              <div
                key={video.jobId}
                className="bg-white shadow-sm rounded-lg overflow-hidden transition duration-300 ease-in-out transform hover:shadow-lg hover:-translate-y-1"
              >
                <div className="aspect-[9/16] bg-gray-100">
                  {video.status === 'Completed' && video.video_url ? (
                    <video
                      src={video.video_url}
                      controls
                      className="w-full h-full"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      {video.status === 'Processing' ? (
                        <div className="text-center">
                          <div className="w-10 h-10 border-t-4 border-blue-600 border-solid rounded-full animate-spin mx-auto mb-3"></div>
                          <p className="text-sm text-gray-700 font-medium">Creating video...</p>
                        </div>
                      ) : video.status === 'Failed' ? (
                        <p className="text-sm text-red-600 font-medium">
                          Sorry, this video had an error. Please try again.
                        </p>
                      ) : (
                        <p className="text-sm text-gray-700 font-medium">Video is processing...</p>
                      )}
                    </div>
                  )}
                </div>
                <div className="p-4 relative">
                  <p className="text-sm text-gray-800 font-medium pr-8">
                    Prompt: {video.inputPrompt ? truncateText(video.inputPrompt, 20) : 'No prompt available'}
                  </p>
                  {video.inputPrompt && (
                    <button 
                      onClick={() => copyToClipboard(video.inputPrompt, video.jobId)} 
                      className={`absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors duration-400 ${copiedId === video.jobId ? 'text-green-500' : ''}`}
                    >
                      <FaRegCopy size={14} />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          {videos.length < totalVideos && (
            <div className="mt-6 text-center">
              <button
                onClick={handleLoadMore}
                className="bg-black text-xs text-white py-2 px-6 rounded hover:bg-gray-800 focus:outline-none"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default View;
