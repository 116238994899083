// Pricing.js
import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import { HiOutlineCheckCircle, HiCheckCircle } from 'react-icons/hi2';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/month' },
];

const tiers = [
  {
    name: 'Hobby',
    id: 'tier-basic',
    price: { monthly: '$12', annually: '$9' },
    annualBilling: '$108/yr',
    features: ['500 Monthly Credits', 'Basic Quality', 'Email Support'],
    mostPopular: false,
    priceId: { monthly: 'price_1Q3f5YKp1iT8v8bYdsk5TV77', annually: 'price_1Q3f5vKp1iT8v8bYZJ4x8gB7' },
  },
  {
    name: 'Growth',
    id: 'tier-pro',
    price: {
      monthly: (
        <>
          <span className="text-gray-400 text-[22px] sm:text-[26px] line-through">$59</span>{' '}
          <span className="text-blue-600">$27</span>
        </>
      ),
      annually: (
        <>
          <span className="text-gray-400 text-[22px] sm:text-[26px] line-through">$50</span>{' '}
          <span className="text-blue-600">$22</span>
        </>
      ),
    },
    annualBilling: '$264/yr',
    features: [
      <span key="feature1">
        2,000 Monthly Credits <span className="text-blue-600 font-bold">+ 1,000 Bonus</span>
      </span>,
      'Highest Quality Videos',
      'Quickest Video Generation',
      'AI Prompt Enhancer',
      'Priority Email Support',
    ],
    mostPopular: true,
    priceId: { monthly: 'price_1Q3f6sKp1iT8v8bYPD1NSLUO', annually: 'price_1Q3f7HKp1iT8v8bY3hwoNw7J' },
  },
  {
    name: 'Scale',
    id: 'tier-enterprise',
    price: { monthly: '$97', annually: '$80' },
    annualBilling: '$960/yr',
    features: [
      '12,000 Monthly Credits',
      'Highest Quality Videos',
      'Quickest Video Generation',
      'AI Prompt Enhancer',
      'Priority Email Support',
    ],
    mostPopular: false,
    priceId: { monthly: 'price_1Q3f7yKp1iT8v8bYXoTZv4s6', annually: 'price_1Q3f8HKp1iT8v8bYKNwUoUax' },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Pricing = ({ onGetStarted }) => {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const navigate = useNavigate(); // Initialize navigate for programmatic navigation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButtonClick = (priceId) => {
    if (onGetStarted && typeof onGetStarted === 'function') {
      onGetStarted(priceId);
    } else {
      // Default behavior: store priceId and navigate to register
      localStorage.setItem('selectedPriceId', priceId);
      navigate('/register');
    }
  };

  return (
    <div className="bg-white py-8 sm:py-24 lg:py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-600">Pricing</h2>
          <p className="mt-2 text-4xl sm:text-4xl font-bold tracking-tight text-black">
            You're one click away from{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-800">
              AI Magic
            </span>
          </p>
        </div>
        <ul className="mx-auto mt-6 max-w-2xl text-center text-xs sm:text-sm leading-6 sm:leading-8 text-black flex flex-wrap justify-center gap-4">
          <li className="flex items-center gap-x-2">
            <HiCheckCircle className="h-4 w-4 text-blue-600 flex-shrink-0" aria-hidden="true" />
            <span className="whitespace-nowrap">3-Day Refund Policy</span>
          </li>
          <li className="flex items-center gap-x-2">
            <HiCheckCircle className="h-4 w-4 text-blue-600 flex-shrink-0" aria-hidden="true" />
            Quick Support
          </li>
          <li className="flex items-center gap-x-2">
            <HiCheckCircle className="h-4 w-4 text-blue-600 flex-shrink-0" aria-hidden="true" />
            Instant Access
          </li>
        </ul>
        <div className="mt-12 sm:mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-base sm:text-sm font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-blue-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-4 py-2 sm:px-3 sm:py-1'
                  )
                }
              >
                {({ checked }) => (
                  <span className={checked ? 'text-white' : ''}>{option.label}</span>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-8 sm:mt-10 grid max-w-[320px] sm:max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? 'ring-2 ring-blue-600 shadow-[0_0_20px_rgb(0,0,255,0.5)]'
                  : 'ring-1 ring-gray-200',
                'rounded-3xl p-6 sm:p-8 xl:p-10 bg-white shadow-lg transform transition duration-500 hover:scale-105'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-blue-600' : 'text-black',
                    'text-2xl sm:text-xl font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular && (
                  <p className="rounded-full bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600">
                    55% OFF!
                  </p>
                )}
              </div>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-[42px] sm:text-5xl font-bold tracking-tight text-black">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-black">
                  {frequency.priceSuffix}
                </span>
              </p>
              {frequency.value === 'annually' && (
                <p className="mt-1 text-xs text-black">
                  Billed Annually as {tier.annualBilling}
                </p>
              )}
              <button
                onClick={() => handleButtonClick(tier.priceId[frequency.value])}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-gradient-to-r from-blue-500 to-blue-800 text-white'
                    : 'text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300',
                  'mt-6 block rounded-md px-3 py-2 w-full text-center text-sm font-semibold leading-6'
                )}
              >
                Get Started
              </button>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-black font-semibold">
                {tier.features.map((feature, index) => (
                  <li key={index} className="flex gap-x-3">
                    <HiOutlineCheckCircle className="h-6 w-5 flex-none text-blue-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
              {tier.mostPopular && (
                <p className="mt-6 text-xs text-gray-600">
                  *Pricing discount and credit bonus continues every month when purchased during launch sale.
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
