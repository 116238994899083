import React from 'react';
import { FaFire } from "react-icons/fa";

const VideoSlider = () => {
  const videos = [
    {
      url: "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/1114.mp4",
      style: "Dark Fantasy",
      description: "Gritty and atmospheric vibe.",
      borderColor: 'border-gray-800',
      tagColor: 'text-gray-800'
    },
    {
      url: "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/0923(11)_comp.mp4",
      style: "Realistic", 
      description: "High quality videos with realism.",
      borderColor: 'border-blue-800',
      tagColor: 'text-gray-800'
    },
    {
      url: "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/1114%20(1)(1).mp4",
      style: "Animation",
      description: "Engaging 3D CGI content.",
      borderColor: 'border-green-800',
      tagColor: 'text-gray-800'
    },
    {
      url: "https://delivery626.nyc3.cdn.digitaloceanspaces.com/compressedvids/1114%20(2).mp4",
      style: "Mystic",
      description: "High detail fantasy videos",
      borderColor: 'border-purple-800',
      tagColor: 'text-gray-800'
    },
  ];
  return (
    <div className="relative bg-gray-50 px-4 md:px-8 overflow-hidden py-16">
      <div className="max-w-6xl mx-auto flex flex-col items-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-16 text-black font-extrabold text-center px-3">
          The software behind <span className="bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">viral</span> faceless accounts
        </h2>

        <div className="flex flex-col md:flex-row gap-12 items-center justify-center">
          {videos.map((video, index) => (
            <div 
              key={index}
              className="w-[280px] md:w-[200px] flex-shrink-0"
            >
              <div className="relative aspect-[9/16] mb-4">
                <video
                  className={`w-full h-full border-2 ${video.borderColor} rounded-xl shadow-lg`}
                  style={{ objectFit: 'cover' }}
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={video.url}
                />
                {index === 0 && (
                  <div className="absolute top-2 right-2 bg-gradient-to-r from-orange-400 to-red-600 text-white text-[12px] px-3 py-1 rounded-full flex items-center gap-1 font-semibold">
                    <FaFire className="text-yellow-300" />
                    HOT
                  </div>
                )}
              </div>
              
              <div>
                <h3 className={`text-2xl font-bold mb-2 ${video.tagColor} text-left`}>
                  {video.style}
                </h3>
                
                <p className="text-gray-600 text-sm min-h-[40px] text-left">
                  {video.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;
