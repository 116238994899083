// Profile.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../icons/vslogo.png';
import { fetchWithTokenRefresh } from '../utils/fetchWithTokenRefresh';
import Pricing from '../components/Pricing';

const MessagePopup = ({ content, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-sm mx-auto">
      <button className="absolute top-0 right-0 text-2xl font-semibold p-4" onClick={onClose}>
        ×
      </button>
      <div className="text-center">
        <div className="text-6xl mb-4">🎉</div>
        <h2 className="text-2xl font-bold mb-4">Subscription Status</h2>
        <div className="mb-8" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="flex justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Profile = () => {
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [messagePopupContent, setMessagePopupContent] = useState('');
  const [credits, setCredits] = useState(null);
  const [showPricingPopup, setShowPricingPopup] = useState(false);

  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const fetchCreditsAndSubscription = async () => {
      try {
        const [creditsResponse, subscriptionResponse] = await Promise.all([
          fetchWithTokenRefresh('https://api.vidstudio.ai/user/credits', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }),
          fetchWithTokenRefresh('https://api.vidstudio.ai/user/subscription', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        ]);

        const creditsData = await creditsResponse.json();
        if (creditsResponse.ok) {
          setCredits(creditsData.credits);
        } else {
          console.error('Failed to fetch credits:', creditsData.message);
          setCredits('Error');
        }

        const subscriptionData = await subscriptionResponse.json();
        if (subscriptionResponse.ok) {
          setSubscriptionStatus(subscriptionData.status);
          setStripeCustomerId(subscriptionData.stripeCustomerId);
          setEmail(subscriptionData.email);
        } else {
          console.error('Failed to fetch subscription:', subscriptionData.message);
          setSubscriptionStatus('inactive');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setCredits('Error');
        setSubscriptionStatus('Error');
      }
    };

    fetchCreditsAndSubscription();
  }, []);

  const handleUpgrade = async (priceId) => {
    try {
      const response = await fetchWithTokenRefresh(
        'https://api.vidstudio.ai/create-checkout-session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            priceId,
            email,
            customerId: stripeCustomerId || null,
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        window.location.href = data.url;
      } else {
        console.error('Failed to create checkout session:', data.error);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const handleManageSubscription = async () => {
    if (subscriptionStatus !== 'active' || !stripeCustomerId) {
      setShowPricingPopup(true);
    } else {
      try {
        const response = await fetchWithTokenRefresh(
          'https://api.vidstudio.ai/create-portal-session',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              customerId: stripeCustomerId,
            }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          window.location.href = data.url;
        } else {
          console.error('Failed to create portal session:', data.error);
        }
      } catch (error) {
        console.error('Error creating portal session:', error);
      }
    }
  };

  return (
    <div className="bg-gray-100 text-gray-800 min-h-screen">
      <nav className="bg-white shadow">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex space-x-4">
              <div>
                <Link
                  to="/dashboard/generate"
                  className="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900"
                >
                  <img src={Logo} alt="Logo" className="h-14 w-auto" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="bg-white p-4 rounded-lg shadow mb-4 inline-block">
          <span className="text-lg font-semibold mr-2">Credits Remaining:</span>
          <span className="text-xl font-bold text-blue-600">
            {credits !== null ? credits.toLocaleString('en-US') : 'Loading...'}
          </span>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-2xl font-bold mb-2 text-gray-800">Account</h2>
          <div className="mt-4 flex space-x-4">
            <button
              onClick={handleManageSubscription}
              className="bg-black hover:bg-gray-800 text-white text-sm font-semibold py-2 px-4 rounded"
            >
              Manage Subscription
            </button>
            <a
              href="mailto:support@vidstudio.ai"
              className="bg-blue-700 hover:bg-blue-600 text-white text-sm font-semibold py-2 px-4 rounded"
            >
              Contact Us
            </a>
          </div>
        </div>
        {showMessagePopup && (
          <MessagePopup
            content={messagePopupContent}
            onClose={() => setShowMessagePopup(false)}
          />
        )}
        {showPricingPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-7xl mx-auto relative">
              <button className="absolute top-0 right-0 text-2xl font-semibold p-4" onClick={() => setShowPricingPopup(false)}>
                ×
              </button>
              <Pricing onGetStarted={handleUpgrade} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
