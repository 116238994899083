// HeroSection.js
import React from 'react';
import { HiLightningBolt } from 'react-icons/hi';
import { FaTiktok, FaInstagram } from 'react-icons/fa';
import { IoLogoYoutube } from 'react-icons/io';

const HeroSection = () => {
  const handleScrollToPricing = () => {
    const pricingSection = document.getElementById('pricing-sec');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-4 mt-8 md:py-10 text-center">
      {/* Replace Trusted Badge with Launch Sale message */}
      <div className="inline-flex items-center space-x-2 bg-blue-50/30 px-4 py-2 rounded-full">
        <p className="text-sm text-blue-600 font-extrabold">
          LAUNCH SALE! · 55% OFF + 1k Credit Bonus!
        </p>
      </div>

      {/* Main Text */}
      <div className="mt-8 space-y-4">
        <h1 className="font-extrabold" style={{ fontSize: '40px', fontWeight: 900, lineHeight: 1.2, color: '#000000', letterSpacing: '-0.02em' }}>
          <span className="md:text-[55px] pr-3">Create viral AI clips in</span> 
          <span className="bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text relative inline-block md:text-[55px]">seconds.
            <svg className="absolute -bottom-0.5 w-full max-h-1.5" viewBox="0 0 55 5" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
              <path d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002" stroke="currentColor" strokeWidth="4"></path>
            </svg>
          </span>
        </h1>
        <p className="text-md text-black mx-auto max-w-[300px]">
          Your new favorite tool for generating AI videos entirely from text.
        </p>
      </div>

      {/* CTA Button with White Glow */}
      <div className="relative mt-12">
        <div className="absolute -inset-2 rounded-full bg-white opacity-50 blur-md"></div>
        <button 
          className="relative bg-white px-12 py-3 rounded-full shadow-lg flex items-center gap-2 hover:bg-gray-100"
          onClick={handleScrollToPricing}
        >
          <HiLightningBolt className="w-4 h-4 text-gray-900" />
          <span className="font-medium text-gray-900">Try VidStudio Now</span>
        </button>
      </div>

      {/* Bottom Section */}
      <div className="mt-auto pt-16 space-y-4">
        <p className="text-sm text-black">
          Powering creators on
        </p>
        <div className="flex justify-center items-center space-x-6">
          <FaTiktok className="text-2xl text-black" />
          <IoLogoYoutube className="text-2xl text-black" />
          <FaInstagram className="text-2xl text-black" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
