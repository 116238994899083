// src/components/gen/StyleSelector.jsx
import React from 'react';

const styles = [
  { 
    label: 'Default', 
    value: 'realistic', 
    image: 'https://imagedelivery.net/Ze5D2t9VVJMFNTqhCbpFZg/00d53d79-aeb1-4f81-165c-3978764e4a00/public' 
  },
  { 
    label: 'Dark Fantasy', 
    value: 'darkf', 
    image: 'https://imagedelivery.net/Ze5D2t9VVJMFNTqhCbpFZg/c05a7964-da80-46f2-7f42-1c9b6521ae00/public'
  },
  { 
    label: 'Ancient Civilization', 
    value: 'ancient', 
    image: 'https://imagedelivery.net/Ze5D2t9VVJMFNTqhCbpFZg/5e068fa7-f8fa-4867-f2c4-826e0a9fe500/public'
  },
  { 
    label: 'Realistic', 
    value: 'fantasy', 
    image: 'https://imagedelivery.net/Ze5D2t9VVJMFNTqhCbpFZg/bd6f2597-2879-4633-3368-433c0dc73b00/public'
  },
  { 
    label: '3D Animation', 
    value: '3d', 
    image: 'https://imagedelivery.net/Ze5D2t9VVJMFNTqhCbpFZg/5d8bba79-10af-4cc8-cb10-d9b06b2c2900/public'
  }
];

const StyleSelector = ({ selectedStyle, setSelectedStyle }) => {
  return (
    <div className="flex gap-4 overflow-x-auto pb-1 scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-100">
      <div className="flex gap-6 p-2">
        {styles.map((style) => (
          <div 
            key={style.value} 
            className={`flex-none w-40 h-48 relative group flex flex-col gap-3 cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105 ${
              selectedStyle === style.value 
                ? 'ring-2 ring-blue-500 rounded-xl shadow-lg' 
                : 'hover:shadow-lg'
            }`}
            onClick={() => setSelectedStyle(style.value)}
            data-preset={style.value.toUpperCase()}
          >
            <div className="absolute inset-0 rounded-xl overflow-hidden">
              <img 
                loading="lazy" 
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105" 
                src={style.image} 
                alt={style.label} 
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <span className="text-white font-medium text-sm tracking-wide">
                {style.label}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StyleSelector;
