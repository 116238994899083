import React from 'react';

const FAQs = () => {
  return (
    <>
      <div className="text-4xl font-bold mb-8 text-black max-w-2xl mx-auto px-4 pt-5 sm:px-6 text-center">Frequently Asked Questions</div>
      <div className="collapse text-black max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-blue-600 shadow-md mb-4">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          What type of content can I create?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">Our video AI model can generate a wide variety of creative content! However, we do not support:</p>
          <ul className="list-disc pl-6 mt-2 font-bold">
            <li>NSFW or suggestive content of any type</li>
            <li>Content featuring real public figures</li>
            <li>Violent or gory content</li>
            <li>Copyrighted material</li>
          </ul>
        </div>
      </div>
      <div className="collapse text-black bg-white max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-blue-600 shadow-md mb-4">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          What if I need to cancel?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">You can easily cancel with one click! No emails, no waiting, just click and you won't be charged again.</p>
        </div>
      </div>
      <div className="collapse text-black bg-white max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-blue-600 shadow-md mb-4">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          Do I own the AI content I create?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">Absolutely! You retain full ownership rights to all content generated through VidStudio. You're free to use, modify, and monetize your AI-created content however you'd like.</p>
        </div>
      </div>
      <div className="collapse text-black bg-white max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-blue-600 shadow-md mb-20">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          What if I want a refund?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">We offer a 3-day guarentee! If you are unsatisfied with your subscription send us an email and we will provide a full refund for any unused credits.</p>
        </div>
      </div>
    </>
  );
};

export default FAQs;
