import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { HiOutlineSparkles } from "react-icons/hi2";
import { FaRegCopy } from "react-icons/fa6";
import { fetchWithTokenRefresh } from "../utils/fetchWithTokenRefresh";
import StyleSelector from "../components/gen/StyleSelector";

const Images = () => {
  const [inputValue, setInputValue] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(false);
  const [credits, setCredits] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState("realistic");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [imagesPerPage] = useState(9);
  const [totalImages, setTotalImages] = useState(0);
  const [processingJobId, setProcessingJobId] = useState(null);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const fetchUserData = async () => {
      try {
        // Fetch credits
        const creditsResponse = await fetchWithTokenRefresh(
          "https://api.vidstudio.ai/user/credits",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const creditsData = await creditsResponse.json();
        if (creditsResponse.ok) {
          setCredits(creditsData.credits);
        } else {
          console.error("Failed to fetch credits:", creditsData.message);
          setCredits("Error");
        }

        // Fetch subscription info
        const subscriptionResponse = await fetchWithTokenRefresh(
          "https://api.vidstudio.ai/user/subscription",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const subscriptionData = await subscriptionResponse.json();
        if (subscriptionResponse.ok) {
          setSubscriptionStatus(subscriptionData.status);
          setStripeCustomerId(subscriptionData.stripeCustomerId);
        } else {
          console.error("Failed to fetch subscription:", subscriptionData.message);
          setSubscriptionStatus("inactive");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setCredits("Error");
        setSubscriptionStatus("Error");
      }
    };

    fetchUserData();

    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const fetchImages = async (page = 1, limit = 9) => {
    try {
      const response = await fetchWithTokenRefresh(
        `https://api.vidstudio.ai/user/pictures?page=${page}&limit=${limit}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setImages(prevImages => {
          const imagesMap = new Map();
          prevImages.forEach(img => imagesMap.set(img.jobId, img));
          data.pictures.forEach(img => imagesMap.set(img.jobId, img));
          return Array.from(imagesMap.values()).sort((a, b) => 
            new Date(b.startedAt) - new Date(a.startedAt)
          );
        });
        setTotalImages(data.total);
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleCreate = async () => {
    if (!inputValue.trim()) {
      alert("Please enter a prompt.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetchWithTokenRefresh(
        "https://api.vidstudio.ai/trigger-pic",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: inputValue,
            style: selectedStyle,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setProcessingJobId(data.jobId);
        // Start polling
        intervalRef.current = setInterval(() => {
          fetchImages(1, currentPage * imagesPerPage);
        }, 5000);
        setInputValue(""); // Clear input after successful submission
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error("Error creating:", error);
      alert("Failed to create image.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpgrade = async () => {
    navigate("/profile");
  };

  // Helper function to render the credits or the Upgrade button
  const renderCreditsOrUpgrade = () => {
    if (credits === null) {
      return (
        <div className="bg-black text-white rounded-full px-3 py-1 text-sm font-semibold">
          Loading...
        </div>
      );
    } else if (credits === 0) {
      return (
        <button
          onClick={handleUpgrade}
          className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 text-white rounded-full px-3 py-1 text-sm font-semibold hover:from-blue-500 hover:to-blue-700 transition-all duration-300"
        >
          Upgrade
        </button>
      );
    } else if (credits === "Error") {
      return (
        <div className="bg-red-500 text-white rounded-full px-3 py-1 text-sm font-semibold">
          Error
        </div>
      );
    } else {
      return (
        <div className="bg-black text-white rounded-full px-3 py-1 text-sm font-semibold">
          {credits}
        </div>
      );
    }
  };

  useEffect(() => {
    fetchImages(currentPage, imagesPerPage);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [currentPage, imagesPerPage]);

  useEffect(() => {
    const hasProcessingImage = images.some(
      img => img.status === 'Processing' && img.jobId === processingJobId
    );
    
    if (!hasProcessingImage && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setProcessingJobId(null);
    }
  }, [images, processingJobId]);

  const handleLoadMore = () => {
    setCurrentPage(prev => prev + 1);
  };

  const renderImageGrid = () => (
    <div className="mt-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {images.map((image) => (
          <div
            key={image.jobId}
            className="bg-white shadow-sm rounded-lg overflow-hidden transition duration-300 ease-in-out transform hover:shadow-lg hover:-translate-y-1"
          >
            <div className="aspect-square bg-gray-100">
              {image.status === 'Completed' && image.imageUrl ? (
                <img
                  src={image.imageUrl}
                  alt={image.inputPrompt}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  {image.status === 'Processing' ? (
                    <div className="text-center">
                      <div className="w-10 h-10 border-t-4 border-blue-600 border-solid rounded-full animate-spin mx-auto mb-3"></div>
                      <p className="text-sm text-gray-700 font-medium">Creating image...</p>
                    </div>
                  ) : image.status === 'Failed' ? (
                    <p className="text-sm text-red-600 font-medium">
                      {image.error || 'Failed to create image'}
                    </p>
                  ) : (
                    <p className="text-sm text-gray-700 font-medium">Image is processing...</p>
                  )}
                </div>
              )}
            </div>
            <div className="p-4 relative">
              <p className="text-sm text-gray-800 font-medium pr-8">
                {image.inputPrompt}
              </p>
            </div>
          </div>
        ))}
      </div>
      {images.length < totalImages && (
        <div className="mt-6 text-center">
          <button
            onClick={handleLoadMore}
            className="bg-black text-xs text-white py-2 px-6 rounded hover:bg-gray-800 focus:outline-none"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {!isMobile && <Sidebar />}
      <div className="flex-1 p-6 md:p-8 lg:p-12 relative overflow-y-auto max-w-full">
        {!isMobile ? (
          <div className="absolute top-4 right-7 flex items-center space-x-4">
            {renderCreditsOrUpgrade()}
            <Link
              to="/profile"
              className="text-sm text-black font-semibold hover:underline"
            >
              Account
            </Link>
          </div>
        ) : (
          <div className="flex justify-between mb-4">
            <button
              onClick={() => navigate("/dashboard/view")}
              className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
            >
              Gallery
            </button>
            <div className="flex items-center space-x-2">
              {renderCreditsOrUpgrade()}
              <button
                onClick={() => navigate("/profile")}
                className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
              >
                Account
              </button>
            </div>
          </div>
        )}
        <div className="overflow-visible">
          {/* Header Section */}
          <h1 className="text-2xl font-bold text-gray-800 mb-6">
            Generate a new image
          </h1>

          {/* Step 1: Input Container */}
          <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 transition-all duration-300 hover:shadow-lg mb-6 mt-3">
            <div>
              <div className="flex items-center mb-3">
                <span className="w-5 h-5 rounded-full bg-black text-white text-xs flex items-center justify-center mr-2">
                  1
                </span>
                <h3 className="text-lg font-bold text-gray-700">Input your prompt</h3>
              </div>
              <p className="text-xs text-gray-500 mb-6">
                Describe the image you want to generate. Be as detailed as possible for better results.
              </p>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="w-full h-10 px-3 text-black text-sm focus:outline-none border border-gray-300 rounded-lg bg-white"
                placeholder="A majestic dragon soaring over ancient ruins at sunset"
              />
            </div>
          </div>

          {/* Step 2: Style Selection */}
          <div className="bg-white max-w-5xl shadow-md rounded-xl p-6 transition-all duration-300 hover:shadow-lg mb-6">
            <div className="space-y-6">
              <div className="flex items-center mb-3">
                <span className="w-5 h-5 rounded-full bg-black text-white text-xs flex items-center justify-center mr-2">
                  2
                </span>
                <h3 className="text-xl font-bold text-gray-700">Select your style</h3>
              </div>
              <StyleSelector
                selectedStyle={selectedStyle}
                setSelectedStyle={setSelectedStyle}
              />
            </div>
          </div>

          <div className="flex items-end">
            <button
              onClick={handleCreate}
              className="bg-black text-white font-medium text-sm py-2.5 px-6 rounded-lg shadow-sm hover:bg-gray-900 transition-all duration-300 flex items-center justify-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="grid min-h-[12px] w-full place-items-center">
                  <svg
                    className="text-gray-300 animate-spin"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="currentColor"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="currentColor"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="text-gray-900"
                    ></path>
                  </svg>
                </div>
              ) : (
                <>
                  Generate Image
                  <HiOutlineSparkles className="h-3.5 w-3.5 ml-1.5 text-white" />
                </>
              )}
            </button>
            <span className="text-gray-500 text-[12px] ml-3">
              Cost: 50 credits
            </span>
          </div>
        </div>
        {renderImageGrid()}
      </div>
    </div>
  );
};

export default Images; 